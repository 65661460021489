.why-chs-us-mn-sc {
  width: 100%;
  background-color: #f4f4f4; /* Optional background for the whole section */
  padding: 40px 0;
}

.why-chs-us-mn-sc .hd-dv {
  width: 1400px;
  margin: 0px auto;
  padding-top: 25px;
}
.why-chs-us-mn-sc .hd-dv h1 {
  font-size: 32px;
  font-weight: 600;
  color: rgb(50, 50, 50);
  text-align: left;
}
.why-chs-us-mn-sc .hd-dv p {
  font-size: 16px;
  color: rgb(70, 70, 70);
  text-align: left;
}

.why-chs-us-mn-sc-cntr {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

.left-side {
  width: 75%;
}

.right-side-hm {
  width: 25%;
  background: linear-gradient(135deg, #e5302f, #ff5722); /* Red Gradient */
  padding: 20px;
  color: white;
  text-align: center;
}

.right-side-hm .content-hm {
  margin-top: 30px;
}

.right-side-hm i {
  font-size: 30px;
}

.right-side-hm h3 {
  font-size: 24px;
  /* margin-top: 10px; */
}

.right-side-hm p {
  font-size: 16px;
  margin: 10px 0;
}

.right-side-hm button {
  padding: 10px 20px;
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
  background-color: #e5302f;
  border: none;
  color: white;
}

.left-side .top {
  display: flex;
  justify-content: space-between;
}

.left-side .top-left,
.left-side .top-right {
  width: 48%;
  padding: 25px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.left-side .top-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.left-side .top-left i,
.left-side .top-right i {
  font-size: 30px;
  margin-bottom: 10px;
  color: rgb(70, 70, 70);
}

.left-side .top-left h3,
.left-side .top-right h3 {
  font-size: 22px;
  /* margin-bottom: 10px; */
  font-weight: 600;
  color: rgb(70, 70, 70);
}

.left-side .top-left p,
.left-side .top-right p {
  font-size: 15px;
  color: #333;
}

.left-side .bottom {
  margin-top: 30px;
}

.left-side .bottom-content {
  padding: 20px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.left-side .bottom-content {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.left-side .bottom-content i {
  font-size: 30px;
  margin-bottom: 10px;
  color: rgb(70, 70, 70);
}

.left-side .bottom-content h3 {
  font-size: 22px;
  font-weight: 600;
  color: rgb(70, 70, 70);
}

.left-side .bottom-content p {
  font-size: 15px;
  color: #333;
}

/* Responsiveness for smaller screens */

@media (max-width: 1400px) {
  .why-chs-us-mn-sc-cntr {
    width: 100%;
    padding: 25px;
  }
  .why-chs-us-mn-sc .hd-dv {
    width: 100%;
    padding: 50px;
  }
}

@media (max-width: 1200px) {
  .why-chs-us-mn-sc-cntr {
    flex-direction: column;
    align-items: center;
  }

  .left-side,
  .right-side-hm {
    width: 100%;
  }

  .left-side .top {
    flex-direction: column;
  }

  .left-side .top-left,
  .left-side .top-right {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .left-side .top-left,
  .left-side .top-right {
    width: 100%;
    padding: 15px;
  }

  .right-side-hm {
    margin-top: 25px;
    border-radius: 10px;
  }
  .right-side-hm .content-hm {
    margin-top: 20px;
  }

  .right-side-hm i {
    font-size: 25px;
  }

  .right-side-hm h3 {
    font-size: 20px;
  }

  .right-side-hm p {
    font-size: 16px;
  }

  .right-side-hm button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .left-side .top-left i,
  .left-side .top-right i {
    font-size: 20px;
  }

  .left-side .top-left h3,
  .left-side .top-right h3 {
    font-size: 20px;
  }

  .left-side .bottom-content i {
    font-size: 20px;
  }

  .left-side .bottom-content h3 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .left-side .top-left,
  .left-side .top-right {
    width: 100%;
  }

  .right-side-hm button {
    width: 100%;
  }
}
