/* Main Section */
.cmp-ovr-vw-sc {
  width: 100%;
  background-color: #f9f9f9; /* Light background for contrast */
  padding: 40px 20px; /* Spacing around the section */
}

.container {
  width: 100%;
  max-width: 1400px; /* Fixed width for content */
  margin: 0 auto; /* Center the content */
  padding: 0 20px; /* Inner padding */
}

/* Main Heading */
.main-heading {
  font-size: 2.2rem;
  /* font-weight: bold; */
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

/* Introduction Paragraph */
.intro-paragraph {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.4;
}

/* Subsections */
.subsections {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Responsive grid */
  gap: 40px; /* Spacing between subsections */
}

.subsection {
  background: #fff;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for modern touch */
  padding: 20px;
  transition: transform 0.3s ease; /* Animation for hover effect */
}

.subsection:hover {
  transform: translateY(-5px); /* Subtle hover animation */
}

.subsection h3 {
  font-size: 1.4rem;
  color: #444;
  margin-bottom: 10px;
}

.subsection ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 10px;
}

.subsection li {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  width: 100%;
}

.subsection p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}
