.Hero_Section {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../Images/homeHeroBGImage.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainDivision_HeroSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%; /* Adjusted for responsiveness */
  max-width: 1350px; /* Max width to keep it consistent on large screens */
  text-align: center; /* Center the text */
}

.mainDivision_HeroSection h1 {
  font-size: 4rem; /* Adjusted for responsiveness */
  font-weight: 400;
  color: rgb(235, 235, 235);
  margin-bottom: 20px;
  line-height: 75px;
}

.mainDivision_HeroSection p {
  color: rgb(235, 235, 235);
  font-size: 1rem; /* Adjusted font size */
  font-weight: 300;
  margin-bottom: 30px;
}

.mainDivision_HeroSection .btnsDiv_Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.mainDivision_HeroSection .btnsDiv_Hero a {
  text-decoration: none;
}

.mainDivision_HeroSection .btnsDiv_Hero .callNowBTN_Hero,
.mainDivision_HeroSection .btnsDiv_Hero .whatsAppNowBTN_Hero {
  padding: 12px 35px;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
}

.mainDivision_HeroSection .btnsDiv_Hero .callNowBTN_Hero {
  border: 2px solid white;
  background-color: white;
  color: #333;
}

.mainDivision_HeroSection .btnsDiv_Hero .callNowBTN_Hero:hover {
  background-color: #e5302f;
  border: 2px solid #e5302f;
  color: white;
}

.mainDivision_HeroSection .btnsDiv_Hero .whatsAppNowBTN_Hero {
  border: 2px solid #25d366;
  background-color: #25d366;
  color: white;
}

.mainDivision_HeroSection .btnsDiv_Hero .whatsAppNowBTN_Hero:hover {
  background-color: #198d43;
  border: 2px solid #198d43;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .mainDivision_HeroSection h1 {
    font-size: 2.5rem; /* Adjust h1 for smaller screens */
    line-height: 50px;
  }

  .mainDivision_HeroSection p {
    font-size: 0.9rem; /* Adjust paragraph font size */
    padding: 0 15px; /* Padding for better readability on small screens */
  }

  .mainDivision_HeroSection .btnsDiv_Hero {
    flex-direction: column; /* Stack buttons vertically */
    gap: 15px;
  }

  .mainDivision_HeroSection .btnsDiv_Hero .callNowBTN_Hero,
  .mainDivision_HeroSection .btnsDiv_Hero .whatsAppNowBTN_Hero {
    width: 100%; /* Full width for the buttons */
  }
}

@media (max-width: 480px) {
  .mainDivision_HeroSection h1 {
    font-size: 2rem; /* Further reduce font size on very small screens */
    line-height: 40px;
    width: 100%;
    /* background-color: red; */
  }

  .mainDivision_HeroSection p {
    font-size: 0.85rem; /* Adjust paragraph font size */
  }
}
