/* Main Section Styles */
.tst-mn-sc-mn {
  width: 100%;
  padding: 80px 20px;
  background-color: #eff5ff;
}

.testimonials-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

}

.testimonials-container h1 {
  font-size: 30px;
  color: #333;
  /* margin-bottom: 10px; */
}

.testimonials-container p {
  font-size: 16px;
  color: #555;
  margin-bottom: 40px;
}

/* Grid Styles */
.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

/* Testimonial Card Styles */
.testimonial-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
}

/* Header Section */
.testimonial-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.google-logo {
  width: 30px;
  height: auto;
}

.testimonial-card h3 {
  font-size: 18px;
  color: rgb(60,60,60);
  font-weight: 600;
  margin: 0;
}

.testimonial-card p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonials-container h1 {
    font-size: 28px;
  }

  .testimonial-card {
    padding: 15px;
  }

  .testimonial-card h3 {
    font-size: 18px;
  }

  .testimonial-card p {
    font-size: 14px;
  }
}
