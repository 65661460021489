.mchnry-mn-sc {
  width: 100%;
  padding: 20px 0;
  /* background-color: red; */
}

.mchnry-container {
  width: 1300px;
  margin: 0 auto;
}

.mchnry-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.mchnry-search {
  flex: 1;
  padding: 10px 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}
.mchnry-search:focus {
  border: 2px solid rgb(150, 150, 150);
}

.mchnry-filter {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.mchnry-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.mchnry-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}

.mchnry-card img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.mchnry-card h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.mchnry-card p {
  margin-bottom: 10px;
  font-size: 15px;
}

.mchnry-card button {
  padding: 8px 18px;
  background-color: #dc0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mchnry-card button:hover {
  background-color: #c00000;
}

.whatsappbtn {
  margin-left: 10px;
  background-color: #25d366 !important;
}
.whatsappbtn:hover {
  margin-left: 10px;
  background-color: #1cbb57 !important;
}

@media (max-width: 1250px) {
  .mchnry-mn-sc {
    padding-top: 50px;
  }

  .mchnry-controls {
    flex-direction: column;
    gap: 10px;
  }
  .mchnry-search,
  .mchnry-filter {
    width: 100%;
  }
  .mchnry-container {
    width: 90%;
  }
}
