/* Overlay Section */
.lead-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Form Container */
.lead-form-container {
  background-color: rgb(240, 240, 240);
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

/* Close Button */
.lead-form-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  /* background-color: red; */
  height: fit-content;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  transition: color 0.3s ease;
}

.lead-form-close-btn:hover {
  color: #000;
}

/* Form Title */
.lead-form-title {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

/* Input Field */
.lead-form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

/* Submit Button */
.lead-form-submit {
  background-color: #e60303;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.lead-form-submit:hover {
  background-color: #d10202;
}
