.LocationSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 0px;
  flex-direction: column;
}

.mainDivision_LocationSection {
  display: flex;
  justify-content: center;
  padding: 50px 0px;
  width: 1200px;
}

.contentDiv_Address {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}
.contentDiv_Address h3 {
  width: 100%;
  font-size: 18px;
  color: #32353f;
  font-weight: 600;
  padding: 5px 0px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.contentDiv_Address p {
  width: 100%;
  font-size: 15px;
  color: #32353f;
  padding: 5px 0px;
  padding-top: 0;
}
.contentDiv_Address p a {
  width: 100%;
  font-size: 15px;
  color: #32353f;
  padding: 5px 0px;
  padding-top: 0;
  text-decoration: none;
}

.mapDiv_Map {
  display: flex;
  justify-content: end;
  align-items: end;
  width: 50%;
}
.mapDiv_Map iframe {
  width: 70%;
  height: 400px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

/* Responsiveness */

@media screen and (max-width: 1100px) {
  .LocationSection {
    padding: 25px;
  }

  .mainDivision_LocationSection {
    width: 100%;
    padding: 0;
  }

  .mapDiv_Map {
    width: 50%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .mapDiv_Map iframe {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .mainDivision_LocationSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .contentDiv_Address {
    width: 100%;
  }
  .mapDiv_Map {
    width: 100%;
    margin-top: 10px;
  }
}

/* End of Responsiveness */
