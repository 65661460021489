/* Main Section */
.history-section {
  width: 100%;
  padding: 50px 20px;
  background-color: #ffffff;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}
.container p {
  font-size: 16px;
}

/* Title */
.history-title {
  font-size: 2.4rem;
  font-weight: 600;
  color: #333;
  /* margin-bottom: 40px; */
}

/* Timeline */
.timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px 0;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ccc;
  z-index: 1;
}

/* Timeline Item */
.timeline-item {
  position: relative;
  width: calc(100% / 6); /* 5 items equally spaced */
  text-align: center;
  z-index: 2;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #ccc;
  color: #333;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
  z-index: 3;
}

.line {
  width: 2px;
  height: 50px;
  background-color: #ccc;
  margin: 0 auto;
}

.content {
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  margin-top: 10px;
  transition: transform 0.3s ease; /* Animation for hover effect */
}

.content:hover {
  transform: translateY(-3px); /* Moves the element upwards */
}

.content h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 5px;
}

.content p {
  font-size: 0.9rem;
  color: #555;
}

/* Responsive Design */
@media (max-width: 900px) {
  .timeline {
    flex-direction: column;
  }

  .timeline-item {
    width: 100%;
    margin-bottom: 40px;
  }

  .timeline::before {
    height: 0;
  }
}
