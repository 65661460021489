.faq-sc-mn {
  width: 100%;
  padding: 50px;
  background-color: #f4f4f4;
}
.faq-sc-mn h1 {
  text-align: center;
  max-width: 1400px;
  margin: 0px auto;
}
.faq-sc-mn p {
  width: 100%;
  text-align: center;
  max-width: 1400px;
  margin: 0px auto;
}

.mn-dv-faq-sc {
  width: 1400px;
  margin: 0px auto;
  margin-top: 25px;
}

@media screen and (max-width: 1400px) {
  .mn-dv-faq-sc {
    width: 100%;
    padding: 25px;
  }
}
