/* General Container */
.sp-container {
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
}

.sp-wrapper {
  width: 100%;
  max-width: 1400px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Post Image */
.sp-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Main Heading */
.sp-heading {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}

/* Introductory Text */
.sp-intro {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: left;
  width: 100%;
}

/* Subheadings */
.sp-content {
  margin-bottom: 20px;
}

.sp-subsection {
  margin-bottom: 30px;
}

.sp-subheading {
  font-size: 22px;
  color: #444;
  margin-bottom: 10px;
}

.sp-text {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  text-align: justify;
}

/* Outro Section */
.sp-outro {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  text-align: left;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  /* General Container */
  .sp-container {
    padding-top: 60px;
  }

  .sp-wrapper {
    padding: 15px;
  }

  .sp-heading {
    font-size: 24px;
  }

  .sp-intro,
  .sp-text,
  .sp-outro {
    font-size: 16px;
  }

  .sp-subheading {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .sp-heading {
    font-size: 20px;
  }

  .sp-intro,
  .sp-text,
  .sp-outro {
    font-size: 14px;
  }

  .sp-subheading {
    font-size: 18px;
  }
}
