.mn-sc-cntc-hr {
  height: 100vh;
  background: url(../../Images/homeHeroBGImage.jpg); /* Background image */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  z-index: 0;
  position: relative; /* Make sure the pseudo-element is positioned correctly */
}

.mn-sc-cntc-hr::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay with 50% opacity */
  z-index: -1; /* Make sure the overlay stays behind the content */
}

.hero-content {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1400px; /* Keeps it centered with max width */
  align-items: stretch;
}

.hero-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh; /* Equal height for both sections */
}

.hero-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh; /* Equal height for both sections */
}

.hero-left {
  padding-right: 10px;
}

.hero-right {
  padding-left: 10px;
}

.hero-left h1 {
  font-size: 4rem;
  color: rgb(240, 240, 240);
  margin-bottom: 5px;
}
.hero-left span {
  color: #ed3a3d;
}

.hero-left p {
  color: #fff;
  font-size: 16px;
}

.cta-button {
  margin-top: 20px;
  padding: 10px 25px;
  background-color: #c90404;
  color: #fff;
  border: none;
  cursor: pointer;
  width: fit-content;
  font-size: 1rem;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
  background-color: #fffaf9;
  border-radius: 0px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 50px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-input {
  padding: 6px;
  margin: 10px 0;
  border: 1px solid #ccc;
  background-color: transparent;
  border-radius: 5px;
  outline: none;
  font-size: 0.95rem;
  width: 100%; /* Ensure inputs in the same row have equal width */
}
.form-input:focus {
  border: 1px solid rgb(200, 200, 200);
}

.form-row .form-input {
  width: 48%;
}
textarea {
  min-height: 200px;
  max-height: 300px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #c90404;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

/* Responsive design */
@media (max-width:1300px){
  .hero-left h1 {
    font-size: 3.5rem;
  }
}
@media (max-width:1200px){
    .hero-left h1 {
      font-size: 2rem;
      margin-top: 30px;
    }
    .cta-button{
      margin-top: 20px;
      padding: 10px 25px;
    }
    textarea {
      min-height: 100px;
      max-height: 200px;
    }
    .form-input{
      margin: 5px 0;
    }
    .enquiry-form{
      padding: 20px;
      margin-top: 60px;
    }
    .hero-right h1{
      font-size: 30px;
    }
}
@media (max-width: 768px) {
  .mn-sc-cntc-hr {
    height: fit-content;
    padding-top: 60px;
  }

  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .hero-left h1 {
    font-size: 2rem;
  }
  .hero-left p {
    font-size: 13px;
    font-weight: 300;
  }

  .hero-left,
  .hero-right {
    width: 100%;
    padding: 10px;
    height: auto; /* Remove fixed height on smaller screens */
  }

  .form-row {
    flex-direction: column;
  }

  .form-row .form-input {
    width: 100%;
    margin-bottom: 10px;
  }
}
