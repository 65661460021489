/* General Styles */
.floating-buttons {
  position: fixed;
  z-index: +5;
}

a {
  text-decoration: none;
}

/* WhatsApp Button */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25d366;
  color: white;
  /* padding: 10px; */
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 20px;
}

.whatsapp-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Call Now Button */
.call-button {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #e60303;
  color: white;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 20px;
}

.call-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
/* Shake Animation */
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.shake {
  animation: shake 1s ease infinite;
}

/* Social Media Section */
.floating-social-media {
  position: fixed;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.social-link {
  display: inline-block;
  margin: 5px;
  color: #333;
  font-size: 20px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-link:hover {
  transform: scale(1.1);
  color: #007bff;
}

/* Icons */
.fab,
.fas {
  display: inline-block;
}

/* Tooltip for Call Now */
.call-tooltip {
  position: fixed;
  bottom: 50px;
  right: 70px;
  background-color: #e60303;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 8px;
  animation: fadeIn 0.5s ease, bounce 1s infinite ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
