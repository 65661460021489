/* Base Styles */
.Navbar_MainSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
}
.Navbar_MainSection {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
  transition: transform 0.3s ease-in-out;
}

.navbar-show {
  transform: translateY(0);
}

.navbar-hide {
  transform: translateY(-100%);
}

/* Upper Section Styling */
.Navbar_Section_Upper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(40, 40, 40);
  color: white;
  padding: 10px 0;
}

.mainDivision_NavbarSection_Upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1350px;
}

.textMachinery_NavbarUpperSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.textMachinery_NavbarUpperSection h3 {
  font-size: 14px;
  font-weight: 300;
}

.socialmediaIcons_Navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 10px;
}

.socialmediaIcons_Navbar a {
  text-decoration: none;
}

.socialmediaIcons_Navbar i {
  color: white;
}

.socialmediaIcons_Navbar i:hover {
  color: #e5302f;
}

/* Middle Section Styling */
.NavbarSection_Both {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.Navbar_Section_Middle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mainDivision_Navbar_Middle_Division {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.logoDiv_Navbar {
  display: flex;
  justify-content: center;
  width: calc(40% - 50px);
  background-color: white;
  height: 100px;
  padding: 10px 0;
}

.logoDiv_Navbar a {
  text-decoration: none;
}

.logoDiv_Navbar a img {
  max-width: fit-content;
  max-height: 100%;
}

.contactDetailsDiv_Navbar {
  display: flex;
  justify-content: flex-start;
  width: calc(60% + 50px);
  background-color: #e5302f;
  border-left: 100px solid white;
  border-bottom: 100px solid transparent;
  box-sizing: border-box;
  height: 100px;
  gap: 50px;
}

.contactDetailsDiv_Navbar p {
  position: relative;
  top: 40px;
  gap: 10px;
  font-size: 15px;
  color: rgb(233, 233, 233);
}

.contactDetailsDiv_Navbar p a {
  text-decoration: none;
  color: rgb(233, 233, 233);
}

/* Lower Section Styling */
.Navbar_Section_Lower {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mainDivision_Navbar_Section_Lower {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1350px;
  padding: 13px 0;
  background-color: rgb(40, 40, 40);
  color: white;
}

.mainDivision_Navbar_Section_Lower ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.mainDivision_Navbar_Section_Lower ul li {
  list-style: none;
}

.mainDivision_Navbar_Section_Lower ul li a {
  text-decoration: none;
  color: rgb(245, 245, 245);
  font-size: 14px;
  font-weight: 300;
  gap: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.mobile-menu {
  position: fixed;

  top: 0;
  right: -150%;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  background-color: white;
  color: black;
  /* padding-top: 50px; */
  z-index: 4;
  /* background-color: red; */
  top: 60px;
}

.mobile-menu.open {
  right: 0;
}

.mainDivision_Navbar_Section_Lower ul li a:hover {
  color: #e5302f;
}
.hamburger-icon {
  display: none;
}
/* Mobile Styles */
@media (max-width: 900px) {
  .Navbar_Section_Upper {
    display: none;
  }

  .NavbarSection_Both {
    display: none;
  }

  .hamburger-icon {
    position: fixed;
    top: 0;
    padding: 10px;
    background-color: white;
    font-size: 24px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    z-index: +5;
    border-bottom: 1px solid rgb(200, 200, 200);
  }

  .hamburger-icon img {
    width: 150px;
  }

  .mobile-logo img {
    width: 100px;
  }

  .close-menu {
    background: none;
    border: none;
    font-size: 24px;
    color: black;
    cursor: pointer;
  }

  .mobile-links {
    list-style: none;
    padding: 0;
  }

  .mobile-links li {
    padding: 10px;
  }

  .mobile-links li a {
    color: black;
    font-size: 15px;
  }

  .mobile-contact p {
    color: black;
    font-size: 14px;
    margin: 15px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile-social i {
    color: black;
    font-size: 20px;
    margin: 10px;
  }
}
