.mn-sc-clients {
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  background-color: rgb(250, 250, 250);
}

.carousel-container {
  width: 90%;
  margin: 0 auto;
  padding: 25px 0;
}

.carousel-container h1 {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: rgb(50, 50, 50);
}

.carousel-container p {
  width: 100%;
  text-align: center;
  color: rgb(50, 50, 50);
  font-size: 16px;
}

.client-slide {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-logo {
  max-width: 250px;
  aspect-ratio: 3/2;
  object-fit: contain;
  margin: 10px;
  border: 1px solid rgb(200, 200, 200);
  padding: 25px 50px;
  border-radius: 5px;
  /* background: white; */
}

/* Responsiveness */
@media (max-width: 768px) {
  .carousel-container h1 {
    font-size: 24px;
  }

  .carousel-container p {
    font-size: 14px;
  }

  .client-logo {
    padding: 15px 30px;
  }
}
