.posts-container {
  width: 100%;
  padding: 20px 0;
  padding-top: 50px;
  background-color: #f7f7f7;
}

.posts-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.post-card {
  border-radius: 8px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.post-image-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-overlay-text {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.post-title {
  font-size: 18px;
  margin: 15px 0 10px;
  color: #333;
  width: 100%;
  text-align: left;
}

.post-content {
  font-size: 14px;
  color: #666;
  text-align: left;
  margin-bottom: 10px;
}

.post-button {
  cursor: pointer;
  align-self: flex-start;
  font-size: 15px;
  /* background-color: red; */
}
.post-button:hover {
  color: #e41010;
}
