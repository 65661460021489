/* Container */
.productpage-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1400px; /* Ensures the container doesn't exceed 1400px */
  padding: 20px;
  margin: 0 auto;
}

/* Left Section */
.productpage-left {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 20px;
}

.carousel {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.carousel img {
  border-radius: 8px;
}

/* Right Section */
.productpage-right {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.product-category,
.product-price {
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.product-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
  line-height: 1.5;
}

.product-features {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.product-features li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #444;
}

/* Action Buttons */
.product-actions {
  display: flex;
  gap: 15px;
}

.btn-enquiry,
.btn-whatsapp {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.btn-enquiry {
  background-color: #e60303;
}

.btn-enquiry:hover {
  background-color: #d60303;
}

.btn-whatsapp {
  background-color: #25d366;
}

.btn-whatsapp:hover {
  background-color: #1c9e4a;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .productpage-container {
    padding: 10px;
    padding-top: 50px;
  }

  .productpage-left,
  .productpage-right {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .productpage-container {
    flex-direction: column;
    padding-top: 50px;
  }

  .product-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .product-name {
    font-size: 20px;
  }

  .product-category,
  .product-price {
    font-size: 16px;
  }

  .product-description {
    font-size: 14px;
  }

  .product-features li {
    font-size: 14px;
  }
}

/* Section */
.mn-sc-product-desc {
  width: 100%;
  background-color: #f8f9fa;
  padding: 20px 0;
}

/* Container */
.product-desc-container {
  width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: "Arial", sans-serif;
}

.product-desc-text strong {
  color: #d9534f; /* Highlighted headings in red */
  font-weight: bold;
}

.product-desc-text ul {
  margin: 10px 0;
  padding-left: 20px;
}

.product-desc-text ul li {
  margin-bottom: 8px;
  color: #444;
  list-style-type: disc;
}

/* Description Text */
.product-desc-text {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

/* Table */
.product-desc-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.product-desc-table th,
.product-desc-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 15px;
}

.product-desc-table th {
  background-color: #d9534f;
  color: #fff;
}

.product-desc-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.product-desc-table tr:hover {
  background-color: #f1f1f1;
}

.product-desc-table td {
  color: #555;
  cursor: pointer;
}

.product-desc-table .important {
  color: #d9534f;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 1400px) {
  .product-desc-container {
    width: 90%;
  }

  .product-desc-table th,
  .product-desc-table td {
    font-size: 14px;
    padding: 10px;
  }
}
