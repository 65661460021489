/* Main Hero Section */
.mn-sc {
  position: relative; /* Allows positioning of the overlay */
  width: 100%;
  background: url("../../Images/bgImageLines.jpg") no-repeat center center; /* Centers the image */
  background-size: cover;
}

/* Overlay for Opacity */
.mn-sc::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6); /* Adjust the color and opacity */
  z-index: 1; /* Places the overlay behind content */
}

/* Content inside the section */
.mn-sc > * {
  position: relative; /* Keeps content above the overlay */
  z-index: 2;
}
.mn-sc-abt-pg-hr {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 50px;
  height: 100vh;
  display: flex;
  flex-wrap: wrap; /* Enables wrapping on smaller screens */
}

/* Left Section */
.mn-sc-abt-pg-hr .left-section {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.mn-sc-abt-pg-hr .left-section .top-image {
  height: 70%;
}

.mn-sc-abt-pg-hr .left-section .top-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mn-sc-abt-pg-hr .left-section .bottom-content {
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  /* background-color: red; */
}

.mn-sc-abt-pg-hr .left-section .bottom-content .social-icons {
  display: flex;
  gap: 10px;
  margin-top: 25px;
  /* background-color: red; */
}

.social-icons a {
  color: rgb(50, 50, 50);
}

.social-icons a:hover {
  color: rgb(209, 0, 0);
}

.mn-sc-abt-pg-hr .left-section .bottom-content h2 {
  margin: 0;
  font-size: 2rem;
  margin-top: 10px;
}

.mn-sc-abt-pg-hr .left-section .bottom-content p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

/* Right Section */
.mn-sc-abt-pg-hr .right-section {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
}

.mn-sc-abt-pg-hr .right-section .logo {
  /* background-color: rebeccapurple; */
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mn-sc-abt-pg-hr .right-section .logo img {
  width: 250px;
  height: 100px;
  object-fit: contain;
}

.mn-sc-abt-pg-hr .right-section .heading {
  /* background-color: red; */
  height: 70%;
  display: flex;
  text-align: right;
}

.mn-sc-abt-pg-hr .right-section .heading h1 {
  font-size: 4.1rem;
  line-height: 1.3;
  height: fit-content;
  margin: 0;
}

/* Responsive CSS */
@media (max-width: 1400px){
  .mn-sc-abt-pg-hr .right-section .heading h1 {
    font-size: 3.2rem;
  }
  .mn-sc-abt-pg-hr{
    margin-bottom: 150px;
  }
}
@media (max-width: 900px) {
  .mn-sc {
    padding-top: 60px;
  }

  .mn-sc-abt-pg-hr {
    flex-direction: column;
    height: fit-content;
  }

  .mn-sc-abt-pg-hr .left-section,
  .mn-sc-abt-pg-hr .right-section {
    width: 100%;
    padding: 10px;
  }

  .mn-sc-abt-pg-hr .left-section .top-image {
    height: 50%;
  }

  .mn-sc-abt-pg-hr .left-section .bottom-content {
    height: 50%;
  }
  .mn-sc-abt-pg-hr .right-section {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mn-sc-abt-pg-hr .right-section .logo img {
    width: 200px;
  }

  .mn-sc-abt-pg-hr .right-section .heading h1 {
    font-size: 2rem;
    text-align: left;
  }
}

@media (max-width: 600px) {
  .right-section h1 {
    text-align: left;
  }
  .mn-sc-abt-pg-hr .right-section .logo img {
    width: 200px;
    /* background-color: red; */
  }
}

@media (max-width: 480px) {
  .mn-sc-abt-pg-hr .left-section .bottom-content h2 {
    font-size: 1.2rem;
  }

  .mn-sc-abt-pg-hr .left-section .bottom-content p {
    font-size: 0.9rem;
  }

  .mn-sc-abt-pg-hr .right-section .heading h1 {
    font-size: 1.5rem;
  }
}
