.FooterSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: rgb(20, 20, 20);
  padding: 20px 0px;
  padding-bottom: 0;
}

.mainDivision_FooterSection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1300px;
  padding: 20px 0px;
}

.navManu_Divs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.navManu_Divs ul {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
}

.navManu_Divs ul li:nth-child(1) {
  list-style: none;
  color: rgb(188, 188, 188);
  font-size: 15px;
  font-weight: 400;
}

.navManu_Divs ul li {
  list-style: none;
}

.navManu_Divs ul li a {
  color: #f5f8ff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}
.navManu_Divs ul li a:hover {
  color: rgb(198, 198, 198);
}

/* Address Div */

.addressDivCom_Footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.addressDivCom_Footer .addressDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addressDivCom_Footer .addressDiv h1 {
  color: rgb(228, 228, 228);
  font-size: 24px;
  font-weight: 400;
}

.addressDivCom_Footer .addressDiv p {
  color: rgb(228, 228, 228);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.addressDivCom_Footer .addressDiv span {
  color: rgb(228, 228, 228);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 2px 0px;
}
.addressDivCom_Footer .addressDiv span a {
  color: rgb(228, 228, 228);
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 2px 0px;
}

.socialMediaHandles_Footer {
  display: flex;
  gap: 10px;
  padding: 3px 0px;
  padding-top: 0;
}

.socialMediaHandles_Footer i {
  color: white;
}

.socialMediaHandles_Footer i:hover {
  color: rgb(198, 198, 198);
}

/* End of Address Div */

/* Copyright Section Styling */

.copyrightDiv_Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  padding: 15px 0px;
}

.mainDivCopyright {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyrightDiv_Footer p {
  font-size: 14px;
  font-weight: 300;
}
.copyrightDiv_Footer p a {
  color: #3473d4;
  text-decoration: none;
  font-weight: 600;
}

/* End of Copyright Section Styling */

/* Responsiveness of Website */

@media screen and (max-width: 1300px) {
  .copyrightDiv_Footer {
    padding: 15px 25px;
  }

  .FooterSection {
    padding: 25px;
  }

  .mainDivision_FooterSection {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .mainDivCopyright {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .copyrightDiv_Footer p {
    font-size: 13px;
    font-weight: 300;
  }
  .copyrightDiv_Footer p a {
    color: #3473d4;
    text-decoration: none;
    font-weight: 600;
  }

  .mainDivision_FooterSection {
    flex-wrap: wrap;
    gap: 50px;
  }

  .navManu_Divs ul {
    gap: 10px;
  }
  .navManu_Divs ul li:nth-child(1) {
    font-size: 18px;
    color: white;
    font-weight: 500;
  }

  .navManu_Divs ul li a {
    font-size: 14px;
    color: rgb(228, 228, 228);
  }

  .navManu_Divs {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

/* End of Responsiveness of Website */
