/* Base Styles */
.mn-sc-ftrd {
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0;
}

.head-p-para {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 25px;
  text-align: center;
}

.head-p-para h1 {
  font-size: 32px;
  font-weight: 600;
  color: rgb(50, 50, 50);
  /* margin-bottom: 10px; */
  /* background-color: red; */
  text-align: left;
}

.head-p-para p {
  font-size: 16px;
  color: rgb(70, 70, 70);
  text-align: left;
}

/* Container */
.featured-container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* Left Section */
.featured-image-section {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-image-section img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
}

/* Right Section */
.featured-tabs-section {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

/* Individual Tabs */
.featured-tab {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.featured-tab--active {
  background-color: #dddddd;
  border-color: #aaaaaa;
}

.featured-tab__icon {
  font-size: 24px;
  /* margin-bottom: 10px; */
}

.featured-tab__heading {
  font-size: 20px;
  font-weight: 600;
  color: rgb(70, 70, 70);
  /* margin-bottom: 5px; */
}

.featured-tab__paragraph {
  font-size: 15px;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .featured-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .featured-image-section,
  .featured-tabs-section {
    width: 100%;
  }

  .featured-image-section {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .head-p-para h1 {
    font-size: 28px;
  }

  .head-p-para p {
    font-size: 14px;
  }

  .featured-tab__heading {
    font-size: 18px;
  }

  .featured-tab__paragraph {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .head-p-para h1 {
    font-size: 24px;
  }

  .head-p-para p {
    font-size: 13px;
  }

  .featured-tab {
    padding: 15px;
  }

  .featured-tab__icon {
    font-size: 20px;
  }

  .featured-tab__heading {
    font-size: 16px;
  }

  .featured-tab__paragraph {
    font-size: 13px;
  }
}
