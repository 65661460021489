/* Main Section */
.ts-machinery-section {
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0;
}

.ts-machinery-container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
}

/* Header */
.ts-header {
  text-align: center;
  margin-bottom: 30px;
}

.ts-header h1 {
  font-size: 36px;
  font-weight: 600;
  color: rgb(70, 70, 70);
  /* margin-bottom: 10px; */
}

.ts-header p {
  font-size: 16px;
  color: rgb(70, 70, 70);
}

/* Cards Grid */
.ts-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Card */
.ts-card {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.ts-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Card Image */
.ts-card-image-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.ts-card-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.ts-card:hover .ts-card-image {
  transform: scale(1.1);
}

/* Card Overlay */
.ts-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ts-card-image-wrapper:hover .ts-card-overlay {
  opacity: 1;
}

.ts-card-overlay h3 {
  font-size: 24px;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.ts-card-overlay p {
  font-size: 14px;
  margin-bottom: 10px;
}

.ts-card-overlay ul {
  list-style: disc;
  padding-left: 20px;
  font-size: 14px;
}

/* Card Actions */
.ts-card-actions {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ts-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  /* font-weight: bold; */
  cursor: pointer;
  transition: background-color 0.4s ease;
  /* font-family: "Poppins", sans-serif; */
}

.ts-enquiry-btn {
  background-color: #e5302f;
  color: #ffffff;
}

.ts-enquiry-btn:hover {
  background-color: #c32727;
}

.ts-whatsapp-btn {
  background-color: #25d366;
  color: #ffffff;
}

.ts-whatsapp-btn:hover {
  background-color: #128c7e;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ts-header h1 {
    font-size: 28px;
  }

  .ts-header p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .ts-header h1 {
    font-size: 24px;
  }

  .ts-header p {
    font-size: 12px;
  }

  .ts-btn {
    font-size: 12px;
    padding: 8px 15px;
  }
}
